import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Axios from 'axios';

import thau from '../../images/thau.png';
import lnk from '../../images/linkedin.png'
import fb from '../../images/facebook-f.png';
import ig from '../../images/Instagram.png';
import fit from '../../images/FIT.jpg';

class Footer extends React.Component {

    state = {
        country: this.props.country || 'be',
        languageSelection: false,
        channel: this.props.channel || 'default',
        modalOpen: false,
        data: "",
        email: "",
        subscribeStatus: ''
    }

    handleChange = (evt) => {

        this.setState({ email: evt.target.value });
    }

    subscribe = async () => {

        try {
            const { data } = await Axios.post('/api/subscribe', {
                email: this.state.email
            });

            if (data.success) {
                this.setState({ subscribeStatus: 'success' });
            }
            else {
                this.setState({ subscribeStatus: 'error' });
            }
        }
        catch (e) {
            this.setState({ subscribeStatus: 'error' });
        }
    }

    startLanguageSelection = () => {
        this.setState({ languageSelection: !this.state.languageSelection });
    }

    handleClick = (e, data) => {

        e.preventDefault();
        this.setState({ modalOpen: true, data });
    }

    closeModal = () => {

        this.setState({ modalOpen: false, data: "" });
    }

    render() {

        const { t, language, country, channel, countries } = this.props;
        const channel_path = `${(channel === 'default' ? '' : '/' + channel).toLowerCase()}`;
        const path = `/${language.toLowerCase()}/${country.toLowerCase()}${channel_path}`;
        var date= new Date();
        var year = date.getUTCFullYear();
        var copyrightTxt = '© 2014- ' + year + ' ' + t('Do It For Me - DIFM BV'); 

        const logoLink = `${path}/`;
        const servicesLink = `${path}/#services`;
        const faqLink = `${path}/faq`;
        const aboutLink = `${path}/about`;

        const subscribeMessage = this.state.subscribeStatus === 'success' ? 'Success' : (this.state.subscribeStatus === 'error' ? 'Invalid Email Id' : '');

        return (
            <footer>
                <div className="preFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="label">{t('Subscribe to our newsletter')}</div>
                                <div className="newsletter-form">
                                    <div className="input-group">
                                        <input type="email" value={this.state.email} onChange={this.handleChange} placeholder={t('Enter your email id')} />
                                        <button onClick={this.subscribe}>{t('Subscribe')}</button>
                                    </div>
                                </div>
                                <div className={`msg ${this.state.subscribeStatus}`}>
                                    {subscribeMessage}
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-5">
                                <div className="label">{t('Follow us on')}</div>
                                <div className="social m-b-b-1">
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/thaudoitforme/">
                                        <img width="30" src={fb} alt="facebook" />
                                    </a>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/do_it_for_me_difm/">
                                        <img width="30" src={ig} alt="instagram" />
                                    </a>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/vzir-retail-services/">
                                        <img width="30" src={lnk} alt="linkedin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="label">
                                    {t('Navigation')}
                                </div>
                                <ul className="navigation">
                                    <li><Link to={logoLink}>{t('Home')}<span>|</span></Link></li>
                                    <li><Link to={servicesLink}>{t('Services')}<span>|</span></Link></li>
                                    <li><Link to={`${path}/#contact_us`}>{t('Contact us')}<span></span></Link></li>
                                    <li><Link to={aboutLink}>{t('About Us')}<span>|</span></Link></li>
                                    <li><Link to={faqLink}>{t('FAQs')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <hr className="footer-separator" />
                        <div className="row">
                            <div className="col-12 Serving">
                                <div className="label">
                                    {t('Serving In')}
                                </div>
                                <ul className="navigation cities">
                                    {
                                        countries.map((country, i, arr) => (

                                            <li key={country.code}>
                                                <Link to={`/${this.props.language.toLowerCase()}/${country.code.toLowerCase()}`}>
                                                    {t(country.code)}
                                                    {arr.length - 1 > i && <span>&nbsp;|&nbsp;</span>}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="label">
                                    {t('About Do It For Me')}
                                </div>
                                {t('DIFM, the leading home services market network, connects homeowners with quality home improvement, repair & maintenance professionals to take care of all your home service needs.')}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="FIT">
                    <div className="container">
                        <div className="row align-items-center">
                                <img alt="Flanders Investment & Trade" src={fit} />   
                        </div>
                    </div>
                </div> */}
                <div className="footer">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 mobile" style={{ textAlign: 'center', marginBottom: '10px' }}>
                                {t('Powered by')}
                                <a href="https://thau.be" target="_blank" rel="noopener noreferrer">
                                    <img alt="THAU" src={thau} height="46" />
                                </a>
                            </div>
                            <div className="col-lg-4">{copyrightTxt}</div>

                            <div className="col-lg-4">
                                <Link to={`${path}/terms`}>{t('Terms')}</Link>
                                |
                                <Link to={`${path}/privacy`}>{t('Privacy')}</Link>
                            </div>
                            <div className="col-lg-4 desktop" style={{ textAlign: 'right' }}>
                                {t('Powered by')}
                                <a href="https://thau.be" target="_blank" rel="noopener noreferrer">
                                    <img alt="THAU" src={thau} height="46" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer.propTypes = {
    t: PropTypes.func
}

export default Footer;
